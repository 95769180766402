body {
  margin: 0;
  font-family: "como", sans-serif;
font-weight: 400;
font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

.menu-button{
  border: none;
}

.color{
  color: dodgerblue; 
}

.bxs{
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  border-radius: 10px;
background: #f5f5f5;
box-shadow:  8px 8px 16px #d9d9d9,
             -8px -8px 16px #f5f5f5;
}

.swiper .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 350px;
  height: 350px;
}

.onBig{
  max-width: 850px;
}
.onBig-2{
  width: 350px;
}

@media screen and (max-width:450px) {
  .myWork{
    margin-top: 30px;
  }
  .myWorkTitle{
    margin-top: 50px;
  }
}
